<template>
    <div v-bind:style="styles">
      <div  v-if="!viLoader">
        <!-- <div class="dispflex">
          <h1 class="hdrtitle" style="margin-top:0">Certificate Request</h1>
          <button
            type="button"
            style="width:auto;height:36px;"
            class="btn btnsml"
            id="svbtn"
            @click="generateSectionOpen"
          >
            Generate Certificate
          </button>
          <button type="button" class="backbtn" @click="$router.go(-1)">
            <i class="fa fa-arrow-left"></i>
          </button>
        </div> -->
        <div class="row">
          <div class="col-lg-12">
            <div class="widjet">
              <div class="widjethdr dispflex">
                <div style="width: auto">
                  <span class="iconsect blubg" style="padding-left: 8px"
                    ><i class="fas fa-users"></i
                  ></span>
                  <h1>Visitor Tracking</h1>
                </div>
                <!-- <button
            type="button"
            style="width:auto;height:36px;"
            class="btn btnsml"
            id="svbtn"
            @click="generateSectionOpen"
          >
            Generate Certificate
          </button> -->
                <div class="dispflex" >
                <div style="width: 200px" class="flexitm">
                  <!-- <span class="has-float-label">
                    <select
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="searchCertificateType"
                    > 
                      <option value=''>--Select--</option>
                      <option
                        v-for="(item, index) in filterCertificateTypes"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <label for="acyearid"
                      >Sort By Certificate Type <span class="required">*</span></label
                    >
                  </span> -->
                  
                </div>
                <div style="width: 200px" class="flexitm">
                </div>
                <div style="width: 200px" class="flexitm">
                  <button style="position: absolute;right: 20px;margin: 8px 0;" class="btn gry_bg tooltipt mpopup twoicn"  @click.prevent="openActionPopUp()">
                  <i class="fas fa-receipt user1"></i>
                        <p class="user2"><i class="fas fa-plus"></i></p>
                        <span class="tooltiptext">Create</span>
                </button>

                    <!-- <button
            type="button"
            style="width:auto;height:36px;"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="openActionPopUp()"
          >
            Create
          </button> -->
                  <!-- <span class="has-float-label">
                    <select
                      class="form-control form-input"
                      id="deptlistid"
                      v-model="searchWords"
                    >
                      <option value=''>--Select--</option>
                      <option
                        v-for="(item, index) in statusList"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <label for="acyearid"
                      >Sort By Status <span class="required">*</span></label
                    >
                  </span> -->
                  
                </div>
              </div>
             
            </div>
            <div class="widjetcontent">
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Student Name</div>
                    <div class="cell">Class</div>
                    <div class="cell">Classroom</div>
                    <div class="cell">Created Date</div>
                    <div class="cell">Created By</div>
                    <div class="cell">Status</div>
  
                    <div class="cell" >Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(data, index) in visitorTrackingList"
                    :key="index"
                  >
                    <!-- <div class="cell" data-title="S.No.">{{ index + 1 }}.</div> -->
                    <div class="cell" data-title="Candidate Name" >{{  data.student ? data.student.firstName + " " + data.student.lastName : '' }}</div>
                    <div class="cell" data-title="Candidate Name" >{{  data.class ? data.class.className : '' }}</div>
                    <div class="cell" data-title="Candidate Name" >{{  data.classroom ? data.classroom.name : '' }}</div>
                    <div  class="cell" data-title="Role">
                            {{ data.updatedDate | dateFormat }}
                          </div>
                    <div class="cell" data-title="Department Name">
                        {{ data.updatedBy ? data.updatedBy.firstName + " " +  data.updatedBy.lastName : '' }}
                      </div>
                    

                    <div class="cell" data-title="Candidate Name" :style="data.status == 'Completed' ? 'color: lawngreen;' : 'color: #ffb100;' ">{{  data.status  }}</div>

  
                   
                      <div class="cell" data-title="Action">
                    <b-dropdown  v-if="data.status == 'Submitted' || data.status == 'Approved'"
                      id="dropdown-1"
                      text=""
                      right
                      style="margin-right: 3px"
                    >
                    <b-dropdown-item v-if="data.status == 'Submitted'"
                       @click.prevent="updateVisitor(data,'Approved')"
                       
                        >Approve</b-dropdown-item
                      >
                      <b-dropdown-item v-if="data.status == 'Submitted'"
                       @click.prevent="updateVisitor(data,'Rejected')"
                       
                        >Reject</b-dropdown-item
                      >
                      <b-dropdown-item v-if="data.status == 'Approved' || data.status == 'Rejected'"
                       @click.prevent="updateVisitor(data,'Completed')"
                       
                        >Completed</b-dropdown-item
                      >
                     
                    </b-dropdown>
                    <button    
                                    class="btn round red tooltipt"
                                    :disabled="data.status == 'Completed'"
                                    @click.prevent="confirmDelete(data)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                    <span class="tooltiptext">Delete</span>
                                  </button>
                    <!-- <button
                      :disabled="data.admissionStatus == 'Active'"
                      class="btn round orange tooltipt"
                      @click.prevent="openEditPopUp(index)"
                    >
                      <i class="fas fa-edit"></i
                      ><span class="tooltiptext">Edit</span>
                    </button>
                    <button
                      class="btn round orange tooltipt"
                      @click.prevent="openViewPopUp(index)"
                    >
                      <i class="far fa-eye"></i
                      ><span class="tooltiptext">View</span>
                    </button> -->
                  </div>
                    
                  </div>
                </div>
              </div>
                          <div class="widjetfooter text-right dk_iconsml" >
  
              <!-- <div class="text-center mt-1" v-if="visitorTrackingList.length > 0">
                <div class="pagination" id="pagination" style="padding-bottom:0">
                  <a
                    :style="
                      currentPageNumber === 1 ? 'pointer-events: none;' : ''
                    "
                    @click="goToPreviousPage"
                    >&laquo;</a
                  >
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a
                    :style="
                      currentPageNumber === lastPageNumber
                        ? 'pointer-events: none;'
                        : ''
                    "
                    @click="goToNextPage"
                    >&raquo;</a
                  >
                </div>
              </div> -->
          </div>
        </div>
      </div>
      
    </div>
     <div>
    
    </div>
      <vi-spinner
        v-if="viLoader"
        text="Loading..."
        textColor="vi-brand-color"
        class="flex-fill h-100 vi-fs12"
        style="
          width: 100%;
          z-index: 111;
          top: 0;
          left: 0;
          background-color: rgba(255, 255, 255, 0.5);
          border-radius: 1rem;
        "
      />
    </div>
    <div>
      <b-modal
        id="createPopUp"
        class="modal"
        no-close-on-backdrop
        no-close-on-esc
      >
      <button type="button" class="close"  @click.prevent="closeVisitor">×</button>
        <div class="contact-form">
          <h2>Create New Gate Pass</h2>
          <br />
          <div>
            <form
              data-vv-scope="admissionValidate"
              id="crtadmission"
              accept-charset="utf-8"
            >
              <div class="form-row">
                
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" >
                    <select
                    v-validate="'required'"
                    class="form-control form-input"
                    id="deptlistid"
                    name="classvisitor"
                    v-model="visitorTracking.class"
                    @change="getSection(visitorTracking.class)"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label
                            v-if="errors.first('admissionValidate.classvisitor')"
                            class="vi-error"
                            style="top:-1em"
                            >Class is required</label
                          >
                    <label for="deptid" v-else
                      >Select Class <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" >
                    <select
                    v-validate="'required'"
                    @change="getStudentList"
                    class="form-control form-input"
                    id="deptlistid"
                    name="sectionvisitor"
                    v-model="visitorTracking.classroom"
                  >
                    <option :value="null">-- Select --</option>

                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <label
                            v-if="errors.first('admissionValidate.sectionvisitor')"
                            class="vi-error"
                            style="top:-1em"
                            >Classroom is required</label
                          >
                    <label for="deptid" v-else
                      >Select Classroom <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label" >
                    <select
                          v-validate="'required'"
                          name="student"
                          v-model="visitorTracking.student"
                          class="form-control form-input"
                          id="sblgname"
                         
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in studentList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName + " " + item.lastName }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('admissionValidate.student')"
                            class="vi-error"
                            style="top:-1em"
                            >Student is required</label
                          >
                    <label for="deptid" v-else
                      >Select Student Name <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <input
                      type="number"
                      name="phoneNumber"
                      v-validate="'required'"
                      class="form-control form-input"
                      id="stdDob"
                    />
                    <label
                            v-if="errors.first('admissionValidate.phoneNumber')"
                            class="vi-error"
                            style="top:-1em"
                            >Phone No is required</label
                          >
                    <label for="deptid" v-else
                      >Phone No <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select
                          v-validate="'required'"
                          name="reason"
                          v-model="visitorTracking.reason"
                          class="form-control form-input"
                          id="sblgname"
                         
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in reasonList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('admissionValidate.reason')"
                            class="vi-error"
                            style="top:-1em"
                            >Reason is required</label
                          >
                    <label for="deptid" v-else
                      >Reason <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-4 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <select
                          v-validate="'required'"
                          name="relation"
                          v-model="visitorTracking.relation"
                          class="form-control form-input"
                          id="sblgname"
                         
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in relationList"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('admissionValidate.relation')"
                            class="vi-error"
                            style="top:-1em"
                            >Relation is required</label
                          >
                    <label for="deptid" v-else
                      >Relation <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div class="form-group input-group col-md-6 mt-3 pr-md-3">
                  <span class="has-float-label">
                    <textarea
                           
                            class="form-control web-form-input"
                            style="min-height:135px;resize:none"
                            id="message"
                            
                            v-model="visitorTracking.description"
                          ></textarea>
                  
                    <label for="deptid"
                      >Description </label
                    >
                  </span>
                </div>
                
              </div>
              <div class="widjetfooter">
                <!-- <div class="dk_icon"></div> -->
                <div class="text-center dk_iconsml">
                  <button type="button"  class="btn btnsml" id="svbtn" @click="saveVisitor">
                      <span>Create</span>
                  </button>
                  <button type="button" class="btn btncl clsmdl" id="clbtn" @click.prevent="closeVisitor">
                       <span>Cancel</span>
                  </button>
                </div>
              </div>
              
            </form>
          </div>
         
        </div>
      </b-modal>
    </div>
    </div>
  </template>
  <script>
  import ViSpinner from "../Common/ViSpinner";
  import ViService from "@/services/ViService";
  import secureUI from "../../utils/secureUI";
  import Select from "vue-select";
  import "vue-select/dist/vue-select.css";
  
  export default {
    name: "candidates",
    data() {
      return {
       
        viLoader: false,
        visitorTrackingList: [],
        classList: [],
        studentList: [],
        sectionList: [],
        visitorTracking: {
          class: null,
          classroom: null,
          student: null,
          reason: null,
          relation: null,
          status: 'Submitted',
          description: '',
          phone: null
        },
        reasonList: [
          'To take back home',
          'Parents Teacher Meeting',
          'Others'
        ],
        relationList: [
          'Father',
          'Mother',
          'Brother',
          'Others'
        ],
        
  
       
      };
    },
    created() {
      this.initCompent()
     
    },
    computed: {
      styles() {
        var brwHeight = window.innerHeight;
        return {
          height: brwHeight - 90 + "px",
        };
      },
     
    },
    watch: {
     
    
    },
    methods: {
      async initCompent(){
      await this.getClassWithSection();
      await this.getVisitorList()
      },
      async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

       if( userData.userDetails.profile.name == "Teacher"){

        const response = await ViService.viXGet("/teacher/getAssignedTeacherwithClassSectionList?viewType=list",userData.token);

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
      
          this.$store.dispatch("setClassAndSectionList", resultData.data);
          this.classList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }

       }else{
        let getClassCounts = this.$store.getters.getClassAndSectionList;

            if (getClassCounts.length == 0) {
            if (!userData) {
              this.$toasted.error("Please login and do the action");
              this.$router.push("/login");
            } else {
              const response = await ViService.viXGet(
                `/academic/class_with_sections`,
                userData.token
              );

              if (response.isSuccess) {
                const resultData = secureUI.secureGet(response.data);
                this.$store.dispatch("setClassAndSectionList", resultData.data);
                this.classList = resultData.data;
              } else {
                this.$toasted.error(response.message);
              }
            }
          } else {
            this.classList = this.$store.getters.getClassAndSectionList;
          }   
       }

      //  if(this.classList.length > 0){
      //   this.selectedClass = this.classList[0]._id
      //   await this.getSection(this.selectedClass)
      //  }

       //selectedClass: null,
      //selectedSection: null,
     
      },
    async saveVisitor() {

      let isFormValid = false;

      await this.$validator.validateAll('admissionValidate').then(result => {
      isFormValid = result;
      });

      if(isFormValid) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
      this.$toasted.error("Please login and do the action");
      this.$router.push('/login');
      } else {
      //this.viLoader = true;
      const response = await ViService.viXPost("/home/saveVisitor", this.visitorTracking,userData.token);

      if (response.isSuccess) {
      this.closeVisitor();
      this.getVisitorList()
      this.$toasted.success(response.message);
      } else {
      this.$toasted.error(response.message);
      // if(response.message == 'Your session has expired, please login'){
      //         localStorage.removeItem('user');
      //         this.$router.push('/login');
      // }
      }
      }
      }else{
      this.$toasted.error('Please fill all the required details');
      }
      },
      async updateVisitor(data,value) {

        let userData = secureUI.sessionGet("user");

        if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
        } else {
          let obj = {
            _id: data._id,
            status: value
          }
        //this.viLoader = true;
        const response = await ViService.viXPost("/home/updateVisitor", obj,userData.token);

        if (response.isSuccess) {
       
        this.getVisitorList()
        this.$toasted.success(response.message);
        } else {
        this.$toasted.error(response.message);
       
        }
        }
      },
      async confirmDelete(data) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viDelete(
          `/home/deleteVisitor/${data._id}`,
          userData.token
        );

        if (response.isSuccess) {
          this.getVisitorList()
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      this.viLoader = false;
    },
    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
  if(this.visitorTracking.class && this.visitorTracking.classroom){
        const response = await ViService.viXGet(`/contact/view/getStudentList?classId=${this.visitorTracking.class}&sectionId=${this.visitorTracking.classroom}`,userData.token);
        if (response.isSuccess) {
          this.studentList = secureUI.secureGet(response.data);
        //  this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    getSection(classId) {
     // this.selectedSection = null
      //this.assignedSubjects = []
      let tempSections = []
    //  const letters = new Set();
      let allSections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");
      

      if( userData.userDetails.profile.name == "Teacher"){
        let sections = allSections.filter( x => x.assignedTeachers.length > 0)

        
        if(sections.length > 0){
          for(let x of sections){
            for(let y of x.assignedTeachers){
              if(y.teacherId && y.teacherId == userData.userDetails._id){
               // this.assignedSubjects.push(y.subjectId)
                tempSections.push(x._id)

              }
            }
          }
          // Create a Set from the array to remove duplicates
          let uniqueSet = new Set(tempSections);

          // Convert the Set back to an array
          let uniqueArray = Array.from(uniqueSet);

          //sections

          this.sectionList = sections.filter((s) => uniqueArray.includes(s._id));

         // this.selectedSection = sections.length > 0 ? sections[0]._id : null
       }
     
      
      //  assignedSubjects
      }
      else{
        this.sectionList = allSections

       // this.selectedSection = this.sectionList.length > 0 ? this.sectionList[0]._id : null

       }
      // this.checkValueChanged()
    },
    async getVisitorList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // let userData = secureUI.sessionGet("user");

      if( userData.userDetails.profile.name != "Teacher"){
        const response = await ViService.viXGet(`/home/allVisitor`,userData.token);
        if (response.isSuccess) {
          this.visitorTrackingList = secureUI.secureGet(response.data);
       
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }else{
      if(this.classList.length > 0){
        let obj = {
          class: this.classList.map( x => x._id)
        }
        const response = await ViService.viXPost("/home/teacher/getVisitorList", obj,userData.token);

     //   const response = await ViService.viXGet(`/teacher/getVisitorList`,userData.token);
        if (response.isSuccess) {
          this.visitorTrackingList = secureUI.secureGet(response.data);
       
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
      }
    },
      openActionPopUp(){
   
      this.$bvModal.show("createPopUp");
    },
    closeVisitor(){
      this.$bvModal.hide("createPopUp");
      this.visitorTracking = {
          class: null,
          classroom: null,
          student: null,
          reason: null,
          relation: null,
          description: '',
          phone: null,
          status: 'Submitted'
        }
    }
    },
    components: {
      "v-select": Select,
      ViSpinner,
    },
  };
  </script>
  
  <style>
  @media screen {
    #printSection {
      
        display: none;
    }
  }
  
  @media print {
    body * {
      visibility:hidden;
    }
    #printSection, #printSection * {
      visibility:visible;
    }
    #printSection {
      position:absolute;
      left:0;
      top:0;
    }
    #no-print,
    #no-print * {
      display: none !important;
    }
  }
  </style>